import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react';
import Blogone from '../components/Blogone';

function Blog1() {
  return (
    <div>
    <Navbar />
    <Blogone />
      <Footer />
    </div>
  );
}

export default Blog1;