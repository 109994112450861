import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react';
import Blogthree from '../components/Blogthree';

function Blog3() {
  return (
    <div>
    <Navbar />
    <Blogthree />
      <Footer />
    </div>
  );
}

export default Blog3;