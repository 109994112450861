import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react';
import Blogtwo from '../components/Blogtwo';

function Blog2() {
  return (
    <div>
    <Navbar />
    <Blogtwo />
      <Footer />
    </div>
  );
}

export default Blog2;